import { FC, MouseEventHandler, PropsWithChildren } from "react";
import { classNames } from "../../utils/classnames";


type ButtonParams = PropsWithChildren<{
  label?: string
  className?: string
  action?: MouseEventHandler<HTMLButtonElement>
  disabled?: boolean
  kind?: "primary" | "secondary"
  type?: "button" | "submit" | "reset"
}>


export const Button: FC<ButtonParams> = ({ label, children, className, action, disabled, type, kind }) => {
  kind = kind ?? "primary";
  return (
    <button
      type={type ?? "button"}
      className={classNames(
        className,
        "text-center rounded-md leading-6 shadow-sm px-3 py-1.5 text-sm font-semibold flex flex-row content-baseline gap-2",
        kind === "primary" && `bg-gray-700 text-white hover:bg-gray-600 hover:scale-105 transition-all duration-75`,
        kind === "secondary" && `border border-gray-700 text-gray-700 hover:bg-gray-200 hover:scale-105 transition-all duration-75`,
        disabled && "opacity-30 cursor-not-allowed",
      )}
      onClick={action}
      disabled={disabled}
    >
      {children ?? label}
    </button>
  );
};
