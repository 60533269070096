import { executeRestAPICall } from "../services/generic.service";
import { CourseProgress } from "./course-progress.types";

const COURSE_PROGRESS_API = `${process.env.REACT_APP_LTI_BACKEND_API}/lti/course-progresses`;

async function getCourseProgress(courseId: number): Promise<CourseProgress> {
  const courseProgress: CourseProgress = await executeRestAPICall({
    method: "GET",
    url: `${COURSE_PROGRESS_API}/${courseId}`,
  });
  return courseProgress;
}

async function createCourseProgress(courseId: number) {
  await executeRestAPICall({
    method: "POST",
    url: `${COURSE_PROGRESS_API}/${courseId}`,
  });
}

async function updateCourseProgress(courseId: number, data: CourseProgress) {
  await executeRestAPICall({
    method: "PUT",
    url: `${COURSE_PROGRESS_API}/${courseId}`,
    data
  });
}
export { getCourseProgress, createCourseProgress, updateCourseProgress };
