import { FC } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { NO_ACCESS_TOKEN } from "../@types/errors.types";
import { useErrorRedirect } from "../app/hooks/useErrorRedirect";
import { PageLayout } from "../components/Pages/PageLayout";
import { CourseDetails } from "./CourseDetails";

export type CourseDetailsParams = {
  id: string;
  ltik?: string;
}

export const CourseDetailsPage: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<CourseDetailsParams>();
  const { ltik } = useParams<CourseDetailsParams>();
  const isDeepLinkSession = !!ltik;
  const ltikInUse = localStorage.getItem("lti_app_token") as string;

  if (!ltikInUse && !ltik) navigate(`/errors/${NO_ACCESS_TOKEN}`);
  if (ltik) localStorage.setItem("lti_app_token", ltik);

  return <PageLayout>
    {!!id && <CourseDetails
      courseId={+id}
      onGoBack={() => isDeepLinkSession ? window.close() : navigate(-1)}
    />}
  </PageLayout>;
};
