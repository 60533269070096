import { decode } from "html-entities";
import { useQuery } from "@tanstack/react-query";
import { FC, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { GENERIC_ERROR_MESSAGE } from "../@types/errors.types";
import { Button, ThumbnailButton } from "../components";
import { Loader } from "../loaders/loader.component";
import { getCourseDetails } from "./course-details.service";
import { getCourseProgress } from "./course-progress.service";
import { useRunCourse } from "./useRunCourse";
import { Modal } from "../components/Modal/Modal";
import { LaunchCourseButton } from "./LaunchCourseButton";
import { CourseProgressState } from "./course-progress.enum";
import { getCertificateByCourseId } from "../certificate/certificate.service";

export type CourseDetailsProps = {
  courseId: number
  onGoBack: () => void
};

const lastExamTakenAtLeast24HoursAgo = (dateStr: string) => {
  const date = new Date(dateStr);
  return Date.now() - date.getTime() > 24 * 60 * 60 * 1000;
};

export const CourseDetails: FC<CourseDetailsProps> = ({ courseId, onGoBack }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { openCourseWindow } = useRunCourse(courseId);
  const [showExamUnavailableModal, setShowExamUnavailableModal] = useState(false);

  const { data: certificate } = useQuery({
    queryKey: ["course_certificate", courseId],
    queryFn: () => getCertificateByCourseId(courseId)
  });

  const { data: course, error: courseError, } = useQuery({
    queryKey: ["course", courseId],
    queryFn: () => getCourseDetails(courseId),
  });

  const { data: courseProgress, error: progressError, } = useQuery({
    queryKey: ["courseProgress", courseId],
    queryFn: () => getCourseProgress(courseId),
  });

  const error = courseError || progressError;

  if (error) navigate(`/errors/${GENERIC_ERROR_MESSAGE}`);
  if (!course) return <Loader />;

  return <div className="container my-12 mx-auto px-4 md:px-12">
    <div>
      <div className="flex flex-row items-stretch gap-4">
        <Button
          label={`❮ ${t("common.goBack")}`}
          action={onGoBack}
        />
        {!!certificate && <Button
          className="flex flex-row items-center gap-2"
          action={() => navigate(`/certificate?course=${courseId}`)}
          kind="secondary">
          <img src="/certificate.svg" alt="" className="w-4 h-4" />
          {t("certificates.goToCertificate")}
        </Button>}
        <Button
          className="flex flex-row items-center gap-2"
          action={() => navigate(`/certificates`)}
          kind="secondary">
          {t("certificates.allCertificates")}
        </Button>
      </div>
      <h1 className="my-8 font-bold text-3xl">{course.title}</h1>
      <div className="relative flex flex-col items-center">
        <img className="w-full max-h-80 object-cover object-center" src={course.thumbnailUrl} alt={course.title} />
        <div className="flex bg-white bg-opacity-30 absolute w-full h-full items-center justify-center">
          <div className="flex flex-col gap-4 items-stretch w-30">
            <LaunchCourseButton
              onLaunchCourse={() => openCourseWindow(course.scormUrl)}
              onStartExam={() => {
                const canTakeExam = !certificate || lastExamTakenAtLeast24HoursAgo(certificate.completedOn);
                if (canTakeExam) {
                  navigate(`/exam/${courseId}`)
                } else {
                  setShowExamUnavailableModal(true);
                }
              }}
              courseProgressState={courseProgress?.state ?? CourseProgressState.NOTSTARTED}
            />
          </div>
        </div>
      </div>
      <div className="w-full flex flex-col gap-8 mt-8">
        {course.description ? (
          <div>
            <h2 className="mt-6 font-semibold">{t("courseDetail.learnerCourseDescription")}</h2>
            <div dangerouslySetInnerHTML={{ __html: decode(course.description).trim() }} className="text-sm leading-relaxed" />
          </div>
        ) : null}
        {course.goal ? (
          <div>
            <h3 className="mt-6 font-semibold">{t("courseDetail.goal")}</h3>
            <div dangerouslySetInnerHTML={{ __html: decode(course.goal).trim() }} className="text-sm leading-relaxed" />
          </div>
        ) : null}
        <div>
          <h3 className="mt-4 font-semibold">{t("courseDetail.rbp")}</h3>
          <p className="text-sm">{course.rbp || t("courseDetail.none")}</p>
        </div>
        {course.contributor ? (
          <div>
            <h3 className="mt-4 font-semibold">{t("courseDetail.author")}</h3>
            <p className="sm:mb-4 text-sm">{decode(course.contributor)}</p>
          </div>
        ) : null}
      </div>
      {showExamUnavailableModal && <Modal
        className="w-[min(80vw,480px)]"
        message={t`courseDetail.exam24HourThrottleMsg`}
        buttons={["ok"]}
        onAction={() => setShowExamUnavailableModal(false)}
        hasXCloseButton
      />}
    </div>
  </div>;
};

