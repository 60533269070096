import { ParseKeys } from "i18next";
import { FC } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../components";
import { CourseProgressState } from "./course-progress.enum";
import { getCourseProgress, updateCourseProgress } from "./course-progress.service";

const ButtonLabels: Readonly<Record<CourseProgressState, ParseKeys>> = {
  [CourseProgressState.NOTSTARTED]: "courseDetail.start",
  [CourseProgressState.INPROGRESS]: "courseDetail.resume",
  [CourseProgressState.FINISHEDWATCHING]: "courseDetail.startExam",
  [CourseProgressState.COMPLETED]: "courseDetail.repeat",
};

export type LaunchCourseButtonProps = {
  courseProgressState: CourseProgressState;
  onLaunchCourse: () => void;
  onStartExam: () => void;
}

export const LaunchCourseButton: FC<LaunchCourseButtonProps> = (props) => {
  const { onLaunchCourse, onStartExam, courseProgressState } = props;
  const action = courseProgressState === CourseProgressState.FINISHEDWATCHING ? onStartExam : onLaunchCourse;
  const { t } = useTranslation();
  return <Button
    type="button"
    className={`rounded-md bg-gray-700 px-8 py-2 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-gray-800`}
    action={action}
    kind="primary"
    label={t(ButtonLabels[courseProgressState])}
  />;
};
