import { useMutation, useQuery, useQueryClient, } from "@tanstack/react-query";
import { isObject } from "lodash";
import { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ScormCourseContent } from "../scorm-support/scorm-course-content";
import { CourseProgressState } from "./course-progress.enum";
import { getCourseProgress, updateCourseProgress } from "./course-progress.service";

const KEY_SUSPEND_DATA = "cmi.suspend_data";
const KEY_LESSON_STATUS = "cmi.core.lesson_status";

export const useRunCourse = (courseId: number) => {
  const navigate = useNavigate();
  const [courseWindow, setCourseWindow] = useState<WindowProxy>();
  const queryClient = useQueryClient();

  const { data: courseProgress } = useQuery({
    queryFn: () => getCourseProgress(courseId),
    queryKey: ["courseProgress", courseId],

  });

  useEffect(() => {
    if (courseProgress)
      localStorage.setItem(KEY_SUSPEND_DATA, courseProgress.position);
  }, [courseProgress]);

  const { mutate: mutateCourseProgress } = useMutation({
    mutationFn: ({ state, position }: { state: CourseProgressState, position: string }) =>
      updateCourseProgress(courseId, { state, position }),
    mutationKey: ["courseProgress", courseId],
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["courseProgress", courseId] });
    },
  });

  const openCourseWindow = (courseUrl: string) => {
    const wnd = window.open("", "_blank", "fullscreen=yes|rel=opener") as WindowProxy;
    wnd.document.write(ScormCourseContent(courseUrl));
    wnd.document.title = "Relias Learning GmbH";
    wnd.onload = () => {
      wnd?.window.postMessage({ ...localStorage });
    };
    if (courseProgress?.state !== CourseProgressState.INPROGRESS) {
      mutateCourseProgress({
        state: CourseProgressState.INPROGRESS,
        position: "started"
      });
    }
    setCourseWindow(wnd);
  };

  const updateLocalStorage = (event: MessageEvent) => {
    if (!event.data || !isObject(event.data)) return;
    const storableKeys = [KEY_SUSPEND_DATA, KEY_LESSON_STATUS];
    storableKeys.forEach((key) => {
      if (key in event.data) localStorage.setItem(key, event.data[key]);
    });
  };

  const handleMessage = useCallback(async (event: MessageEvent) => {
    if (event.data.logout === "logout") {
      courseWindow?.close();
      navigate("/logout");
    }
    if (event.origin !== window.location.origin) return;
    const hasPassed = ["passed", "completed"].includes(event.data[KEY_LESSON_STATUS]);
    const hasSuspendData = !!event.data[KEY_SUSPEND_DATA];

    if (hasPassed) {
      window.removeEventListener("message", handleMessage, false);
    }

    if (hasPassed || hasSuspendData) {
      mutateCourseProgress({
        state: hasPassed
          ? CourseProgressState.FINISHEDWATCHING
          : CourseProgressState.INPROGRESS,
        position: event.data[KEY_SUSPEND_DATA]
      });
    }
    updateLocalStorage(event);
  }, [courseWindow, navigate, mutateCourseProgress]);

  useEffect(() => {
    window.addEventListener("message", handleMessage, false);
    return () => window.removeEventListener("message", handleMessage, false);
  }, [handleMessage]);


  return { openCourseWindow };
};
